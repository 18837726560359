import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { delay, motion } from 'framer-motion';
import '../styles/index.scss';
import { Helmet } from 'react-helmet';

const cardVariantImages = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5 },
  },
  hidden: {
    opacity: 0,
    x: -50,
  },
  whileHover: {},
};

const cardVariantText = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5 },
  },
  hidden: {
    opacity: 0,
    x: 50,
  },
};

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Ian McAbee | UI/UX Designer and Front-End Developer" />
      <div className="container">
        <div className="intro">
          <div>
            <h1
              dangerouslySetInnerHTML={{
                __html: data.wpPage.acfIntro.introHeading,
              }}></h1>
            <p
              dangerouslySetInnerHTML={{
                __html: data.wpPage.acfIntro.introDescription,
              }}></p>
          </div>
        </div>
        <div className="case-studies">
          <ul>
            {data.allWpWorkItem.nodes.map((post, index) => (
              <li className="card" key={index}>
                <motion.div
                  key={index}
                  whileHover={{
                    transition: { duration: 0.5 },
                    scale: 1.075,
                    // backgroundColor: '#ffffff',
                    // backgroundColor: post.acfWork.themeColor,
                  }}
                  transition={{ stiffness: 400, damping: 10 }}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}>
                  <Link to={`/work/${post.slug}`}>
                    <motion.div variants={cardVariantImages}>
                      <img src={post.acfWork.cardImage.sourceUrl} />
                    </motion.div>
                    <motion.div variants={cardVariantText}>
                      <div className="info">
                        <h2
                          dangerouslySetInnerHTML={{
                            __html: post.title,
                          }}></h2>
                        <span className="customer">
                          {post.acfWork.organization}
                        </span>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt,
                          }}></p>
                        <span className="case-study-cta">
                          View case study
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 448 512">
                            <path
                              d="M23.1,232h340.1L230.5,105.4c-9.6-9.2-10-24.4-0.8-33.9c9.2-9.6,24.4-9.9,34-0.8l176,168c4.8,4.6,7.4,10.8,7.4,17.4
	s-2.7,12.9-7.5,17.4l-176,168c-9.6,9.1-24.8,8.8-33.9-0.8c-9.2-9.5-8.8-24.8,0.8-33.9L363.2,280H24c-13.2,0-24-10.8-24-24
	C0,242.8,9.9,232,23.1,232z"
                            />
                          </svg>
                        </span>
                        {/* <Link
                          to={`/work/${post.slug}`}
                          className="case-study-link">
                          View case study{' '}
                          <i className="fa-regular fa-arrow-right"></i>
                        </Link> */}
                      </div>
                    </motion.div>
                  </Link>
                </motion.div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      acfIntro {
        introHeading
        introTraits
        introDescription
        introImage {
          id
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }

    allWpWorkItem(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            id
            sourceUrl
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        acfWork {
          organization
          themeColor
          cardImage {
            id
            sourceUrl
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        excerpt
      }
    }
  }
`;
